import { Theme, createTheme } from "@mui/material";
import { primitiveColors, semanticColors } from "@dfp/isomorphic-design-tokens";
import * as polished from "polished";

type StyleOverrideProps = { theme: Omit<Theme, "components"> };

const defaultFontFamily = [
  "Open Sans",
  "Roboto",
  "Helvetica",
  "Arial",
  "sans-serif",
].join(",");

export const themeRebranding = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: semanticColors.bg,
          color: semanticColors.text,
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        color: "secondary",
        variant: "contained",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.borderRadius.md,
          textTransform: "none",
          textDecoration: "none",
          padding: "9px 16px",
          whiteSpace: "nowrap",
          "& svg": {
            width: "20px",
            height: "20px",
          },
          disabled: ({ theme }: StyleOverrideProps) => ({
            opacity: theme.opacity.disabled,
          }),
        }),
        containedPrimary: {
          "&:hover": {
            background: semanticColors.fillHover,
          },
          "&:active": {
            background: semanticColors.fillActive,
          },
        },
        containedSecondary: ({ theme }) => ({
          backgroundColor: semanticColors.fillSecondary,
          color: theme.palette.text.main,
          "&:hover": {
            background: semanticColors.fillSecondaryHover,
          },
        }),
        containedSuccess: {
          backgroundColor: semanticColors.fillSuccess,
          "&:hover": {
            background: semanticColors.fillInverse,
          },
        },
        containedError: {
          backgroundColor: semanticColors.fillError,
          "&:hover": {
            background: semanticColors.fillErrorHover,
          },
        },
        textPrimary: {
          color: semanticColors.text,
          "&:hover": {
            background: semanticColors.fillSecondaryHover,
          },
        },
        textSecondary: {
          color: semanticColors.text,
          "&:hover": {
            background: semanticColors.fillSecondaryHover,
          },
        },
        textError: {
          color: semanticColors.textError,
          "&:hover": {
            background: semanticColors.fillErrorSecondaryHover,
          },
        },
        containedSizeSmall: () => ({
          borderRadius: "14px",
          padding: "5px 12px",
        }),
        textSizeSmall: () => ({
          borderRadius: "14px",
          padding: "5px 12px",
        }),
        containedSizeLarge: ({ theme }) => ({
          borderRadius: theme.borderRadius.lg,
          padding: "13px 20px",
        }),
        textSizeLarge: ({ theme }) => ({
          borderRadius: theme.borderRadius.lg,
          padding: "13px 20px",
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: semanticColors.icon,
          "&:hover": {
            background: semanticColors.fillTertiaryHover,
          },
        },
        sizeSmall: () => ({
          borderRadius: "14px",
          fontSize: "20px",
          padding: "7px",
        }),
        sizeMedium: ({ theme }) => ({
          borderRadius: theme.borderRadius.md,
          fontSize: "20px",
          padding: "10px",
        }),
        sizeLarge: ({ theme }) => ({
          borderRadius: theme.borderRadius.lg,
          fontSize: "20px",
          padding: "14px",
        }),
        colorPrimary: {
          "&:hover": {
            background: semanticColors.fillTertiaryHover,
          },
        },
        colorSecondary: {
          "&:hover": {
            background: semanticColors.fillTertiaryHover,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: semanticColors.surfaceSecondary,
          borderRadius: "8px",
          padding: "4px",
          gap: "4px",
          "& .MuiToggleButton-root:not(:last-of-type)": {
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
          },
          "& .MuiToggleButton-root:not(:first-of-type)": {
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: "none",
          backgroundColor: semanticColors.surfaceSecondary,
          "&.Mui-selected": {
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: semanticColors.surfaceTertiary,
            },
          },
          "&.Mui-disabled": {
            border: "none",
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
        color: "secondary",
      },
      styleOverrides: {
        underlineHover: {
          "&:hover": {
            textDecoration: "none",
          },
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        elevation: 16,
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        rounded: {
          borderRadius: "18px",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          height: "40px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${semanticColors.borderSecondary}`,
        },
        head: ({ theme }) => ({
          color: semanticColors.textSecondary,
          fontSize: "0.8125rem",
          fontWeight: 400,
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          borderRight: `1px solid ${semanticColors.borderSecondary}`,
          "&th:first-of-type": {
            paddingLeft: theme.spacing(4),
          },
          "&th:last-of-type": {
            paddingRight: theme.spacing(4),
          },
        }),
        body: ({ theme }) => ({
          "&td:first-of-type": {
            paddingLeft: theme.spacing(4),
          },
          "&td:last-of-type": {
            paddingRight: theme.spacing(4),
          },
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${semanticColors.borderSecondary}`,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.main,
        }),
      },
    },
    MuiBadge: {
      defaultProps: {
        color: "primary",
      },
    },
    MuiInputBase: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.main,
          minHeight: "40px",
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.borderRadius.xs,
          "&:hover": {
            backgroundColor: semanticColors.fillNeutralHover,
          },
          "&:hover fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: semanticColors.borderHover,
          },
          "&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: semanticColors.borderPrimary,
          },
          "&.Mui-disabled:hover": {
            backgroundColor: "inherit",
          },
          "&.Mui-disabled fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: semanticColors.borderDisabled,
          },
          "&.Mui-error fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: semanticColors.borderError,
          },
          "&.Mui-error:hover": {
            backgroundColor: semanticColors.surfaceError,
          },
        }),
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        slotProps: {
          paper: {
            elevation: 4,
            sx: {
              borderRadius: (theme) => theme.borderRadius.sm,
            },
          },
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          // the rendering here seems to differ from the docs
          // so we're setting the color explicitly
          ".MuiAutocomplete-popupIndicator": {
            color: theme.palette.text.secondary,
          },
          ".MuiAutocomplete-clearIndicator": {
            color: theme.palette.text.secondary,
          },
        }),
        // same theming as MuiSelect
        paper: ({ theme }) => ({
          ".MuiAutocomplete-listbox": {
            padding: theme.spacing(1),
            maxHeight: 400,
          },
          ".MuiAutocomplete-option": {
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
            borderRadius: theme.spacing(1),
          },
        }),
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          // TODO: After MUI update
          // check slotProps.paper are available
          PaperProps: {
            elevation: 4,
            sx: {
              maxHeight: 400,
              borderRadius: (theme) => theme.borderRadius.sm,
            },
          },
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          ".MuiSelect-icon": {
            // Our Icon components have a different
            // sizing that the MUI icon components
            width: "20px",
            height: "20px",
            right: "10px",
            top: "calc(50% - 10px)",
            color: theme.palette.text.secondary,
          },
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&:before": {
            display: "none",
          },
          borderRadius: "8px!important",
          border: `1px solid ${semanticColors.border}`,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            borderBottom: `1px solid ${semanticColors.border}`,
            boxSizing: "content-box",
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            color: semanticColors.icon,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "16px",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.main,
          borderRadius: theme.borderRadius.md,
          padding: theme.spacing(1, 2),
          [theme.breakpoints.down("sm")]: {
            flexWrap: "wrap",
          },
        }),
        action: ({ theme }) => ({
          padding: theme.spacing(0, 0, 0, 2),
          marginRight: 0,
        }),
        standardInfo: {
          backgroundColor: semanticColors.surfaceInfo,
          border: `1px solid ${semanticColors.borderSecondaryInfo}`,
          "& .MuiAlert-icon": {
            color: semanticColors.iconInfo,
          },
        },
        standardSuccess: {
          backgroundColor: semanticColors.surfaceSuccess,
          border: `1px solid ${semanticColors.borderSecondarySuccess}`,
          "& .MuiAlert-icon": {
            color: semanticColors.iconSuccess,
          },
        },
        standardWarning: {
          backgroundColor: semanticColors.surfaceWarning,
          border: `1px solid ${semanticColors.borderSecondaryWarning}`,
          "& .MuiAlert-icon": {
            color: semanticColors.iconWarning,
          },
        },
        standardError: {
          backgroundColor: semanticColors.surfaceError,
          border: `1px solid ${semanticColors.borderSecondaryError}`,
          "& .MuiAlert-icon": {
            color: semanticColors.iconError,
          },
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: () => ({
          fontWeight: 600,
        }),
      },
    },
    MuiChip: {
      defaultProps: {
        size: "small",
        variant: "filled",
      },
      styleOverrides: {
        root: ({ theme }: StyleOverrideProps) => ({
          borderRadius: theme.borderRadius.xs,
          lineHeight: 1,
          fontWeight: 400,
        }),
        sizeSmall: ({ theme }: StyleOverrideProps) => ({
          fontSize: theme.typography.body3.fontSize,
          height: "24px",
          lineHeight: "24px",
        }),
        labelSmall: () => ({
          padding: "0 10px",
        }),
        sizeMedium: ({ theme }: StyleOverrideProps) => ({
          fontSize: theme.typography.body2.fontSize,
          height: "28px",
          lineHeight: "28px",
        }),
        labelMedium: () => ({
          padding: "2px 10px",
        }),
        filled: {
          backgroundColor: semanticColors.surfaceSecondary,
          color: semanticColors.text,
        },
        filledSuccess: {
          backgroundColor: semanticColors.surfaceSuccess,
          color: semanticColors.textSuccess,
        },
        filledError: {
          backgroundColor: semanticColors.surfaceError,
          color: semanticColors.textError,
        },
        filledWarning: {
          backgroundColor: semanticColors.surfaceWarning,
          color: semanticColors.textWarning,
        },
        filledInfo: {
          backgroundColor: semanticColors.surfaceInfo,
          color: semanticColors.textInfo,
        },
        filledPrimary: {
          backgroundColor: semanticColors.fill,
          color: semanticColors.textInverse,
        },
      } as FIXME,
    },
    MuiAvatar: {
      styleOverrides: {
        root: () => ({
          border: `1px solid ${semanticColors.borderSecondary}`,
          width: "40px",
          height: "40px",
          borderRadius: "17px",
        }),
        colorDefault: ({ theme }) => ({
          color: semanticColors.text,
          backgroundColor: semanticColors.surfaceSecondary,
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.02)",
          borderRadius: theme.borderRadius.md,
          "&:hover": {
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
          },
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: () => ({
          padding: 0,
          margin: "24px",
        }),
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: () => ({
          padding: 0,
          margin: "24px",
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          background: primitiveColors.greyscale[900],
          color: semanticColors.textInverse,
          fontSize: polished.rem(14),
          fontWeight: 400,
          lineHeight: polished.rem(22),
          padding: theme.spacing(2, 3),
          borderRadius: theme.borderRadius.sm,
          boxShadow: `0px 2px 8px 0px ${primitiveColors.greyscale[500]}`,
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginLeft: 0,
          marginRight: 0,
          fontSize: theme.typography.body3.fontSize,
          "&.Mui-error": {
            color: semanticColors.textError,
          },
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          fontSize: theme.typography.body2.fontSize,
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&.Mui-error": {
            color: theme.palette.text.secondary,
          },
          "&.Mui-focused": {
            color: theme.palette.text.secondary,
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: () => ({
          backgroundColor: semanticColors.borderPrimary,
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(2.5, 3),
          minWidth: "80px",
          fontSize: polished.rem(15),
          fontWeight: 600,
          lineHeight: polished.rem(22),
          color: theme.palette.text.secondary,
          "&.Mui-selected": {
            color: semanticColors.text,
          },
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: ({ theme }) => ({
          padding: theme.spacing(1),
          // This is a hack to prevent added padding in MuiSelect when it scrolls
          paddingRight: theme.spacing(1) + " !important",
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: semanticColors.fillNeutralHover,
          },
        }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingLeft: theme.spacing(1.5),
          paddingRight: theme.spacing(1.5),
          borderRadius: theme.spacing(1),
          "&:hover": {
            backgroundColor: semanticColors.fillNeutralHover,
          },
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          minWidth: "unset",
          marginRight: theme.spacing(1),
          color: theme.palette.text.main,
          "& svg": {
            width: 20,
            height: 20,
          },
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& td:first-of-type": {
            paddingLeft: theme.spacing(3),
          },
          "& td:last-of-type": {
            paddingRight: theme.spacing(3),
          },
          "& th:first-of-type": {
            paddingLeft: theme.spacing(3),
          },
          "& th:last-of-type": {
            paddingRight: theme.spacing(3),
          },
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& a": {
            color: theme.palette.info.main,
            textDecoration: "none",
          },
        }),
      },
    },
  },
  avatarSize: {
    56: {
      width: "56px",
      height: "56px",
      borderRadius: "22px",
    },
    48: {
      width: "48px",
      height: "48px",
      borderRadius: "20px",
    },
    40: {
      width: "40px",
      height: "40px",
      borderRadius: "17px",
    },
    32: {
      width: "32px",
      height: "32px",
      borderRadius: "13px",
    },
    28: {
      width: "28px",
      height: "28px",
      borderRadius: "12px",
    },
    24: {
      width: "24px",
      height: "24px",
      borderRadius: "10px",
    },
    20: {
      width: "20px",
      height: "20px",
      borderRadius: "8px",
    },
    16: {
      width: "16px",
      height: "16px",
      borderRadius: "7px",
    },
  },
  borderRadius: {
    xs: "6px",
    sm: "12px",
    md: "18px",
    lg: "21px",
  },
  opacity: {
    disabled: 0.45,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 760,
      md: 1024,
      lg: 1260,
      xl: 1920,
    },
  },
  palette: {
    common: {
      white: primitiveColors.greyscale.white,
      black: primitiveColors.greyscale.black,
    },
    primary: {
      light: semanticColors.textSecondary,
      main: semanticColors.fill,
      contrastText: semanticColors.textInverse,
    },
    secondary: {
      light: semanticColors.fillNeutralHover,
      main: semanticColors.surfaceSecondary,
      dark: semanticColors.surfaceSecondary,
      contrastText: semanticColors.text,
    },
    accent: {
      light: semanticColors.surfaceAccent,
      main: semanticColors.fillAccent,
      dark: semanticColors.fillAccent,
      contrastText: semanticColors.textInverse,
    },
    error: {
      light: semanticColors.surfaceError,
      main: semanticColors.fillError,
      dark: semanticColors.fillErrorHover,
      contrastText: semanticColors.textInverse,
    },
    success: {
      light: semanticColors.surfaceSuccess,
      main: semanticColors.fillSuccess,
      dark: semanticColors.fillSuccess,
      contrastText: semanticColors.textSuccess,
    },
    warning: {
      light: semanticColors.surfaceWarning,
      main: semanticColors.fillWarning,
      dark: semanticColors.fillWarning,
      contrastText: semanticColors.textWarning,
    },
    info: {
      light: semanticColors.surfaceInfo,
      main: semanticColors.fillInfo,
      dark: semanticColors.fillInfo,
      contrastText: semanticColors.textInfo,
    },
    grey: {
      50: primitiveColors.greyscale[50],
      100: primitiveColors.greyscale[100],
      200: primitiveColors.greyscale[200],
      300: primitiveColors.greyscale[300],
      400: primitiveColors.greyscale[400],
      500: primitiveColors.greyscale[500],
      600: primitiveColors.greyscale[600],
      700: primitiveColors.greyscale[700],
      800: primitiveColors.greyscale[800],
      900: primitiveColors.greyscale[900],
    },
    background: {
      main: semanticColors.bg,
      paper: semanticColors.surface,
      accent: semanticColors.surfaceAccent,
    },
    divider: semanticColors.borderSecondary,
    text: {
      main: semanticColors.text,
      primary: semanticColors.text,
      secondary: semanticColors.textSecondary,
      disabled: semanticColors.textDisabled,
    },
    action: {
      disabled: semanticColors.textDisabled,
      disabledBackground: semanticColors.fillDisabled,
    },
  },
  typography: {
    fontFamily: defaultFontFamily,
    h1: {
      fontSize: polished.rem(24),
      fontWeight: 700,
      lineHeight: polished.rem(34),
    },
    h2: {
      fontSize: polished.rem(20),
      fontWeight: 600,
      lineHeight: polished.rem(28),
    },
    h3: {
      fontSize: polished.rem(16),
      fontWeight: 600,
      lineHeight: polished.rem(24),
    },
    h4: {
      fontSize: polished.rem(15),
      fontWeight: 600,
      lineHeight: polished.rem(22),
    },
    body1: {
      fontSize: polished.rem(15),
      fontWeight: 400,
      lineHeight: polished.rem(24),
    },
    body2: {
      fontSize: polished.rem(14),
      fontWeight: 400,
      lineHeight: polished.rem(22),
    },
    body3: {
      fontSize: polished.rem(13),
      fontWeight: 400,
      lineHeight: polished.rem(20),
    },
    button: {
      fontSize: polished.rem(14),
      fontWeight: 600,
      lineHeight: polished.rem(22),
      textTransform: "none",
    },
    overline: {
      fontSize: polished.rem(14),
      fontWeight: 600,
      lineHeight: polished.rem(22),
      textTransform: "uppercase",
    },
  },
});
