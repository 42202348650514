import { createContext, useMemo } from "react";

import { useSnackbar } from "../Snackbar";
import { UserCancelledRequestError } from "../UserCancelledRequestError";

export type ErrorHandlerContextType = {
  handleError: (error: Error) => void;
};

export const ErrorHandlerContext = createContext<
  ErrorHandlerContextType | undefined
>(undefined);

type Props = {
  children: React.ReactNode;
  translateError: (error: Error) => string;
  trackError: (error: Error) => void;
};
export const ErrorHandlerProvider = ({
  children,
  translateError,
  trackError,
}: Props) => {
  const { showSnackbar } = useSnackbar();

  const contextValue: ErrorHandlerContextType = useMemo(
    () => ({
      handleError: (error: Error) => {
        if (error instanceof UserCancelledRequestError) {
          return;
        }
        showSnackbar(translateError(error), "error");
        trackError(error);
      },
    }),
    [showSnackbar, trackError, translateError],
  );

  return (
    <ErrorHandlerContext.Provider value={contextValue}>
      {children}
    </ErrorHandlerContext.Provider>
  );
};
