import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer, Stack, Typography, styled } from "@mui/material";
import { ReactNode, Suspense } from "react";
import { IconButtonContained } from "../Button";
import { semanticColors } from "@dfp/isomorphic-design-tokens";

type Props = {
  open: boolean;
  title: ReactNode | string;
  subtitle?: ReactNode | string;
  onClose: () => void;
  emphasizedContent?: ReactNode;
  children: ReactNode;
};

const border = `1px solid ${semanticColors.border}`;

export function DetailDrawer({
  open,
  title,
  subtitle,
  onClose,
  emphasizedContent,
  children,
}: Props) {
  return (
    <StyledDrawer
      anchor="right"
      variant="temporary"
      open={open}
      onClose={onClose}
    >
      <Suspense>
        <Stack
          direction="column"
          bgcolor="background.main"
          p={4}
          gap={4}
          borderBottom={border}
        >
          <Stack
            direction="row"
            gap={1}
            justifyContent="space-between"
            alignItems="start"
          >
            <Stack direction="column" gap={0.5}>
              <Typography
                pt={0.5}
                variant="h1"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {title}
              </Typography>
              {subtitle ? (
                <Typography
                  variant="body1"
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  {subtitle}
                </Typography>
              ) : null}
            </Stack>
            <IconButtonContained aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButtonContained>
          </Stack>
          {emphasizedContent ? <Box>{emphasizedContent}</Box> : null}
        </Stack>
        <Box p={4}>{children}</Box>
      </Suspense>
    </StyledDrawer>
  );
}

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: "400px",
    overflowY: "auto",
    overflowX: "hidden",
    boxSizing: "border-box",
    border: "none",
    borderRadius: "18px 0 0 18px",
  },
}));
