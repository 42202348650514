import { Alert } from "@mui/material";
import { isAxiosError } from "axios";
import { isEmpty } from "lodash-es";

import { isPlatformRequestError } from "../../Context/API/utils";
import {
  Error401Content,
  Error404Content,
  Error500Content,
  ErrorUnreachableServerContent,
} from "../../Features/ErrorPages";
import { useErrorTranslator } from "../../Hooks/Error";

type SplashErrorProps = {
  error: Error;
};

export function SplashError({ error }: SplashErrorProps) {
  const et = useErrorTranslator();

  if (isPlatformRequestError(error, 400, undefined, "DocumentNotFound")) {
    return <Error404Content />;
  }

  if (
    isPlatformRequestError(error, 401) ||
    isPlatformRequestError(error, 400, "EntitlementError") ||
    isRequestorIdentityPartyRoleTypeError(error)
  ) {
    return <Error401Content />;
  }

  if (isPlatformRequestError(error, 500)) {
    return <Error500Content />;
  }

  if (isAxiosError(error) && !error.response) {
    return <ErrorUnreachableServerContent />;
  }

  return (
    <Alert sx={{ marginBottom: 2 }} severity="error">
      {et(error)}
    </Alert>
  );
}

function isRequestorIdentityPartyRoleTypeError(err: Error) {
  return (
    isPlatformRequestError(err, 400) &&
    !isEmpty(
      err.response?.data?.errorAttributes?.validationErrors?.find(
        (validationError: FIXME) =>
          validationError.errorType === "WrongEnumValue" &&
          validationError.errorAttributes?.attributePath ===
            "requestorIdentity.requestor.partyRoleReference.partyRoleType",
      ),
    )
  );
}
