import {
  ButtonBaseProps,
  IconButton,
  IconButtonProps,
  styled,
} from "@mui/material";
import React, {
  ForwardRefExoticComponent,
  ReactElement,
  RefAttributes,
} from "react";
import { LinkProps } from "react-router-dom";

type Props = {
  children: React.ReactNode;
  to?: string;
  component?: ForwardRefExoticComponent<
    LinkProps & RefAttributes<HTMLAnchorElement>
  >;
  color?: string;
} & ButtonBaseProps &
  IconButtonProps;

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
  },

  "&.MuiIconButton-colorPrimary": {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  "&.MuiIconButton-colorSecondary":
    theme.components?.MuiButton?.styleOverrides?.containedSecondary,
}));

export function IconButtonContained({
  children,
  color,
  ...rest
}: Props): ReactElement {
  return (
    <StyledIconButton color={color} {...rest}>
      {children}
    </StyledIconButton>
  );
}
