import {
  type ReactNode,
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-use";

export type ReplicatedHistoryContext = {
  goBack: (() => void) | undefined;
};

type Props = {
  children: ReactNode | ReactNode[];
};

export const ReplicatedHistoryContext = createContext<
  ReplicatedHistoryContext | undefined
>(undefined);

export function ReplicatedHistoryProvider({ children }: Props) {
  const location = useLocation();
  const [stack, setStack] = useState(
    location.state?.key ? [location.state?.key] : [],
  );
  const previousKeyRef = useRef(undefined);

  useEffect(() => {
    const stackKey = location.state?.key;
    if (!stackKey || stackKey === previousKeyRef.current) {
      return;
    }

    switch (location.trigger) {
      case "load":
        break;
      case "pushstate":
        setStack((prevStack) => {
          return [...prevStack, stackKey];
        });
        break;

      case "popstate":
        setStack((prevStack) => {
          if (prevStack.includes(stackKey)) {
            return prevStack.slice(0, -1);
          } else {
            return [...prevStack, stackKey];
          }
        });
        break;

      case "replacestate":
        setStack((prevStack) => {
          return [...prevStack.slice(0, -1), stackKey];
        });
        break;
    }
    previousKeyRef.current = stackKey;
  }, [location]);

  const contextValue = useMemo(() => {
    const handleGoBack = () => {
      window.history.back();
    };

    return {
      goBack: stack.length > 1 ? handleGoBack : undefined,
    };
  }, [stack.length]);

  return (
    <ReplicatedHistoryContext.Provider value={contextValue}>
      {children}
    </ReplicatedHistoryContext.Provider>
  );
}
