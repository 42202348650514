import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { processError } from "../../Utils/Error/processError";

export function useErrorTranslator() {
  const { t } = useTranslation();

  const translateError = useCallback(
    (error: Error) => {
      const terr = processError(error);
      return t(terr.key, terr.options);
    },
    [t],
  );

  return translateError;
}
