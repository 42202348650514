export const primitiveColors = {
  pear: {
    50: "rgba(249, 252, 226, 1)",
    100: "rgba(243, 249, 197, 1)",
    200: "rgba(234, 243, 163, 1)",
    300: "rgba(225, 237, 128, 1)",
    400: "rgba(211, 230, 66, 1)",
    500: "rgba(196, 222, 3, 1)",
    550: "rgba(163, 196, 11, 1)",
    600: "rgba(131, 169, 19, 1)",
    650: "rgba(98, 143, 27, 1)",
    700: "rgba(65, 117, 35, 1)",
    800: "rgba(33, 90, 43, 1)",
    900: "rgba(0, 64, 51, 1)",
  },
  darkGreen: {
    25: "rgba(226, 244, 238, 1)",
    50: "rgba(196, 233, 220, 1)",
    100: "rgba(167, 222, 203, 1)",
    200: "rgba(134, 203, 183, 1)",
    300: "rgba(100, 184, 163, 1)",
    400: "rgba(67, 166, 142, 1)",
    500: "rgba(33, 147, 122, 1)",
    600: "rgba(0, 128, 102, 1)",
    700: "rgba(0, 107, 85, 1)",
    800: "rgba(0, 85, 68, 1)",
    900: "rgba(0, 64, 51, 1)",
    950: "rgba(0, 32, 26, 1)",
  },
  blueViolet: {
    50: "rgba(246, 239, 251, 1)",
    100: "rgba(222, 204, 255, 1)",
    200: "rgba(187, 149, 255, 1)",
    300: "rgba(157, 104, 255, 1)",
    400: "rgba(134, 69, 255, 1)",
    500: "rgba(124, 55, 224, 1)",
    600: "rgba(114, 41, 193, 1)",
    700: "rgba(104, 28, 161, 1)",
    800: "rgba(94, 14, 130, 1)",
    900: "rgba(84, 0, 99, 1)",
  },
  scarlet: {
    50: "rgba(255, 243, 241, 1)",
    100: "rgba(255, 220, 217, 1)",
    200: "rgba(254, 185, 179, 1)",
    300: "rgba(255, 150, 141, 1)",
    400: "rgba(255, 115, 102, 1)",
    500: "rgba(255, 57, 39, 1)",
    600: "rgba(208, 43, 29, 1)",
    700: "rgba(161, 29, 20, 1)",
    800: "rgba(113, 14, 10, 1)",
    900: "rgba(66, 0, 0, 1)",
  },
  orange: {
    50: "rgba(255, 248, 235, 1)",
    100: "rgba(255, 238, 213, 1)",
    200: "rgba(255, 221, 171, 1)",
    300: "rgba(255, 203, 128, 1)",
    400: "rgba(255, 185, 86, 1)",
    500: "rgba(255, 167, 43, 1)",
    600: "rgba(255, 149, 0, 1)",
    700: "rgba(206, 110, 0, 1)",
    800: "rgba(158, 70, 0, 1)",
    900: "rgba(109, 31, 0, 1)",
  },
  grass: {
    50: "rgba(239, 254, 231, 1)",
    100: "rgba(189, 255, 168, 1)",
    200: "rgba(148, 243, 125, 1)",
    300: "rgba(106, 232, 81, 1)",
    400: "rgba(87, 218, 51, 1)",
    500: "rgba(67, 204, 21, 1)",
    600: "rgba(52, 164, 21, 1)",
    700: "rgba(37, 124, 21, 1)",
    800: "rgba(22, 83, 21, 1)",
    900: "rgba(7, 43, 21, 1)",
  },
  vividSky: {
    50: "rgba(237, 251, 255, 1)",
    100: "rgba(203, 240, 253, 1)",
    200: "rgba(152, 221, 252, 1)",
    300: "rgba(100, 194, 247, 1)",
    400: "rgba(61, 165, 239, 1)",
    500: "rgba(2, 123, 229, 1)",
    600: "rgba(1, 95, 196, 1)",
    700: "rgba(1, 70, 164, 1)",
    800: "rgba(0, 50, 132, 1)",
    900: "rgba(0, 35, 109, 1)",
  },
  greyscale: {
    25: "rgba(242, 245, 245, 1)",
    50: "rgba(229, 236, 235, 1)",
    100: "rgba(204, 217, 214, 1)",
    200: "rgba(178, 198, 194, 1)",
    300: "rgba(153, 179, 173, 1)",
    400: "rgba(128, 159, 153, 1)",
    500: "rgba(102, 140, 133, 1)",
    600: "rgba(77, 121, 112, 1)",
    700: "rgba(51, 102, 92, 1)",
    800: "rgba(26, 83, 71, 1)",
    900: "rgba(0, 64, 51, 1)",
    950: "rgba(0, 45, 36, 1)",
    white: "rgba(255, 255, 255, 1)",
    black: "rgba(0, 0, 0, 1)",
  },
  greyscaleAlpha: {
    50: "rgba(0, 0, 0, 0.019999999552965164)",
    100: "rgba(0, 0, 0, 0.029999999329447746)",
    200: "rgba(0, 0, 0, 0.05000000074505806)",
    300: "rgba(0, 0, 0, 0.07999999821186066)",
    400: "rgba(0, 0, 0, 0.11999999731779099)",
    500: "rgba(0, 0, 0, 0.15000000596046448)",
    600: "rgba(0, 0, 0, 0.30000001192092896)",
    700: "rgba(0, 0, 0, 0.550000011920929)",
    800: "rgba(0, 0, 0, 0.6000000238418579)",
    900: "rgba(0, 0, 0, 0.8999999761581421)",
  },
};
