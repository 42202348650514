import type { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ErrorNumbers } from "./ErrorNumbers";
import { ErrorPageContent } from "./ErrorPageContent";

export function Error404Content(): ReactElement {
  const { t } = useTranslation("features.errorPages");

  return (
    <ErrorPageContent
      graphic={<ErrorNumbers>404</ErrorNumbers>}
      title={t("Error404.title")}
      description={t("Error404.description")}
      buttonTitle={t("Error404.buttonTitle")}
      onButtonClick={() => (window.location.href = "/")}
    />
  );
}
