import { primitiveColors } from "./primitive-colors";

export const semanticColors = {
  bg: primitiveColors.greyscale[25],
  bgSecondary: primitiveColors.greyscale.white,
  bgInverse: primitiveColors.greyscale[900],

  surface: primitiveColors.greyscale.white,
  surfaceSecondary: primitiveColors.greyscale[50],
  surfaceTertiary: primitiveColors.greyscale[100],
  surfaceInverse: primitiveColors.greyscale[900],
  surfaceBrand: primitiveColors.pear[50],
  surfaceAccent: primitiveColors.blueViolet[50],
  surfaceInfo: primitiveColors.vividSky[50],
  surfaceSuccess: primitiveColors.grass[50],
  surfaceWarning: primitiveColors.orange[50],
  surfaceError: primitiveColors.scarlet[50],

  fill: primitiveColors.darkGreen[600],
  fillHover: primitiveColors.darkGreen[700],
  fillActive: primitiveColors.darkGreen[800],
  fillDisabled: primitiveColors.greyscale[50],
  fillSecondary: primitiveColors.greyscale[50],
  fillSecondaryHover: primitiveColors.greyscale[100],
  fillSecondaryActive: primitiveColors.greyscale[200],
  fillTertiaryHover: primitiveColors.greyscale[100],
  fillTertiaryActive: primitiveColors.greyscale[200],
  fillNeutral: primitiveColors.greyscale.white,
  fillNeutralHover: primitiveColors.greyscale[25],
  fillNeutralActive: primitiveColors.greyscale[50],
  fillNeutralDisabled: primitiveColors.greyscale[25],
  fillInverse: primitiveColors.greyscale[900],
  fillBrand: primitiveColors.pear[500],
  fillAccent: primitiveColors.blueViolet[500],
  fillInfo: primitiveColors.vividSky[500],
  fillSuccess: primitiveColors.grass[600],
  fillWarning: primitiveColors.orange[600],
  fillError: primitiveColors.scarlet[600],
  fillErrorHover: primitiveColors.scarlet[700],
  fillErrorActive: primitiveColors.scarlet[800],
  fillErrorSecondary: primitiveColors.scarlet[50],
  fillErrorSecondaryHover: primitiveColors.scarlet[100],
  fillErrorSecondaryActive: primitiveColors.scarlet[200],
  fillEmphasis: primitiveColors.darkGreen[25],
  fillEmphasisHover: primitiveColors.darkGreen[50],
  fillEmphasisActive: primitiveColors.darkGreen[100],

  text: primitiveColors.greyscale[900],
  textSecondary: primitiveColors.greyscale[600],
  textDisabled: primitiveColors.greyscale[300],
  textInverse: primitiveColors.greyscale.white,
  textAccent: primitiveColors.blueViolet[500],
  textBrand: primitiveColors.pear[500],
  textInfo: primitiveColors.vividSky[500],
  textSuccess: primitiveColors.grass[600],
  textWarning: primitiveColors.orange[600],
  textError: primitiveColors.scarlet[600],

  link: primitiveColors.vividSky[500],
  linkHover: primitiveColors.vividSky[600],
  linkActive: primitiveColors.vividSky[700],
  linkDisabled: primitiveColors.greyscale[400],

  icon: primitiveColors.greyscale[900],
  iconSecondary: primitiveColors.greyscale[600],
  iconDisabled: primitiveColors.greyscale[300],
  iconInverse: primitiveColors.greyscale.white,
  iconAccent: primitiveColors.blueViolet[500],
  iconBrand: primitiveColors.pear[500],
  iconInfo: primitiveColors.vividSky[500],
  iconSuccess: primitiveColors.grass[600],
  iconWarning: primitiveColors.orange[600],
  iconError: primitiveColors.scarlet[600],

  backdrop: primitiveColors.greyscaleAlpha[700],

  border: primitiveColors.greyscale[100],
  borderHover: primitiveColors.greyscale[200],
  borderActive: primitiveColors.greyscale[300],
  borderDisabled: primitiveColors.greyscale[50],
  borderPrimary: primitiveColors.darkGreen[600],
  borderSecondary: primitiveColors.greyscale[50],
  borderSecondaryInfo: primitiveColors.vividSky[200],
  borderSecondarySuccess: primitiveColors.grass[200],
  borderSecondaryWarning: primitiveColors.orange[200],
  borderError: primitiveColors.scarlet[600],
  borderSecondaryError: primitiveColors.scarlet[200],
  borderEmphasis: primitiveColors.darkGreen[100],
  borderEmphasisHover: primitiveColors.darkGreen[200],
  borderEmphasisActive: primitiveColors.darkGreen[300],
};
