import { BackendModule } from "i18next";

import { ENABLE_DEV_LANG } from "../../AppConfig";

export const ResourceBackend: BackendModule = {
  type: "backend",
  init: () => null,
  read: (language, namespace, callback) => {
    // load dev lang
    if (language === "dev") {
      if (ENABLE_DEV_LANG !== "yes") {
        return callback(null, false);
      }

      const namespaceModule = import(`../translations/${namespace}.json`);

      return namespaceModule
        .then((module) => callback(null, module.default))
        .catch(() => {
          const error = new Error(
            `Missing namespace ${namespace} file in language ${language}.`,
          );
          console.error(error);
          return callback(error, false);
        });
    }

    // load all other languages
    return import(`../translations-prod/${namespace}.${language}.json`)
      .then((module) => callback(null, module.default))
      .catch((err) => {
        if (err.code === "MODULE_NOT_FOUND") {
          return callback(
            new Error(
              `Undefined resource for namespace '${namespace}', language '${language}'.`,
            ),
            false,
          );
        }

        console.error("i18n: import error");
        console.error({ err });
        return callback(err, false);
      });
  },
};
