import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useCallback } from "react";

export function useErrorTracker(): (error: Error) => void {
  const appInsights = useAppInsightsContext();

  return useCallback(
    (error: Error) => {
      appInsights.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    },
    [appInsights],
  );
}
