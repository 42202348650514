import i18n, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";

import { DEFAULT_LANGUAGE, ENABLE_I18NEXT_DEBUG } from "../AppConfig";
import { ResourceBackend } from "./backends/ResourceBackend";
import { LANGUAGES } from "./languages";

const missingKeyHandler: InitOptions["missingKeyHandler"] = (
  lngs,
  ns,
  key,
  fallbackVal,
  updateMissing,
  opts,
) => {
  console.warn(
    `i18n: Missing '${lngs.join(", ")}' translation for '${ns}:${key}'.`,
    opts,
  );
};

// for all options read: https://www.i18next.com/overview/configuration-options
const options: InitOptions = {
  debug: ENABLE_I18NEXT_DEBUG === "yes",
  defaultNS: "common",
  lng: DEFAULT_LANGUAGE,
  fallbackLng: [],
  supportedLngs: LANGUAGES,
  // call missingKeyHandler on missing keys
  saveMissing: true,
  missingKeyHandler,
  // when translation is missing, show full key including the namespace
  appendNamespaceToMissingKey: true,
  // prefixes the namespace to the returned key when using lng: 'cimode'
  appendNamespaceToCIMode: true,
  interpolation: {
    // not needed for react as it escapes by default
    escapeValue: false,
  },

  load: "currentOnly",
  preload: [DEFAULT_LANGUAGE],

  // load only bare minimum, each app has to load it's translations
  ns: ["common", "errors"],
  react: {
    useSuspense: true,
  },
};

i18n.createInstance().use(initReactI18next).use(ResourceBackend).init(options);
