import { Box, Typography, useTheme } from "@mui/material";
import type { ReactElement, ReactNode } from "react";

type ErrorNumbersProps = {
  children: ReactNode;
};

export function ErrorNumbers({ children }: ErrorNumbersProps): ReactElement {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1.5,
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: 140,
          lineHeight: 1,
          fontWeight: "bold",
          color: theme.palette.text.disabled,
        }}
      >
        {children}
      </Typography>
    </Box>
  );
}
